<template>
<div class="company-slider">
  <div class="company-slider-slider">
    <VueSlickCarousel v-bind="settings" ref="slider">
      <div class="company-slider-slider__item" v-for="company in companies" :key="company">
        <!-- <img class="company-slider-slider__img" src="https://via.placeholder.com/200x60" :alt="company.title"> -->
        <!-- <img class="company-slider-slider__img" :src="company.img" :alt="company.title"> -->
        <h2>{{ company }}</h2>
      </div>
    </VueSlickCarousel>
  </div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  props: ['companies'],
  components: {
    VueSlickCarousel
  },
  data(){
    return ({
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        pauseOnHover: false,
        autoplaySpeed: 1,
        speed: 6000,
        cssEase: "linear"
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.company-slider {
  margin-top: calc(70em / 18);
  margin-bottom: calc(60em / 18);

  &-slider {
    width: 100%;

    &__item {
      padding: 0 calc(25em / 18);
      height: calc(60em / 16);

      h2 {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: calc(-30rem / 18);
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--black);

        }
      }
    }
  }
}
</style>
