``<template>
<div class="rolling-h1">
  <b-container>
    <h1 class="rolling-h1__h1">
      {{ h1 }}
    </h1>
    <div class="rolling-h1__rolling rolling-h1__rolling--desktop">
      <TransitionGroup mode="in-out" duration="900">
        <template v-for="(word, index) in rolling">
          <span v-if="currentIndex === index" :key="word + index">{{ word }}</span>
        </template>
      </TransitionGroup>
    </div>
    <div class="rolling-h1__rolling rolling-h1__rolling--mobile">
      <TransitionGroup mode="in-out" duration="900">
        <template v-for="(word, index) in rollingMobile">
          <span v-if="currentIndex === index" :key="word + index">{{ word }}</span>
        </template>
      </TransitionGroup>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'RollingH1',
  props: ['h1', 'rolling', 'rollingMobile'],
  data(){
    return {
      currentIndex: 0,
      interval: 1500
    }
  },
  methods: {
    nextIndex(){
      if ( this.currentIndex === (this.rolling.length - 1)){
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }
  },
  mounted(){
    setInterval(this.nextIndex, this.interval)
  }
}
</script>

<style lang="scss" scoped>
.rolling-h1 {
  margin-bottom: calc(105em / 18);

  @media only screen and (max-width: 768px){
    margin-bottom: calc(81em / 14);
  }

  > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(44em / 18);

    @media only screen and (max-width: 768px){
      grid-template-columns: 100%;
    }
  }

  &__h1 {
    text-align: right;

    @media only screen and (max-width: 768px){
      text-align: left;
    }
  }

  &__rolling {
    font-size: calc(63em / 18);
    line-height: calc(77 / 63);

    border-bottom: 4px solid var(--red);
    max-width: calc(400rem / 18);
    overflow-y: hidden;

    @media only screen and (max-width: 768px){
      width: calc(400rem / 18);
      height: calc(65rem / 14);
    }

    span {
      display: block;
      position: absolute;
    }

    &--desktop {
      @media only screen and (max-width: 768px){
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media only screen and (max-width: 768px){
        display: block;
      }
    }
  }
}

.v-enter, .v-leave-to {
  opacity: 0;
  transition: .3s;
}

.v-enter-active {
  transition: .3s;
}

.v-enter {
  transform: translateY(50%);
}

.v-leave-to {
  transform: translateY(-50%);
}
</style>
