<template>
  <div class="landing-video" ref="landingvideo">
    <video src="https://player.vimeo.com/progressive_redirect/playback/752808532/rendition/1080p/file.mp4?loc=external&signature=d221383982924aceb707ec9c2abe07818e3fac0b69a271685f6ca9e6aef823ae" @ended="videoEndedCallback()" autoplay playsinline muted></video>
  </div>
</template>

<script>
export default {
  name: 'LandingVideo',
  data(){
    return {
      fadeOutMillisecond: 1000
    }
  },
  methods: {
    preventBodyScroll(){
      document.body.classList.add('no-scroll')
    },
    recoverBodyScroll(){
      document.body.classList.remove('no-scroll')
    },
    videoEndedCallback(){
      // console.log('video ended')
      this.hideComponent();

      setTimeout(
        ()=>{
          this.recoverBodyScroll();
          this.destroyComponent();
        },
        this.fadeOutMillisecond
      )
    },
    hideComponent(){
      this.$refs.landingvideo.classList.add('landing-video--hidden');
    },
    destroyComponent(){
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  mounted(){
    this.preventBodyScroll();
  }
}
</script>

<style lang="scss" scoped>
.landing-video {
  position: fixed;
  z-index: 2048;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: calc(v-bind('fadeOutMillisecond') * 1ms);
  background-color: var(--black);

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--hidden {
    opacity: 0;
  }
}
</style>

<style>
.no-scroll {
  overflow: hidden;
}
</style>
