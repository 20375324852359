<template>
<div>
  <ImgBanner :text="response.imgBanner.text" :img="response.imgBanner.img" />
  <b-container>
    <div class="contact-wrapper">

      <div class="contact-row" v-for="office in response.offices" :key="office.iframe">
        <div class="contact-row-map" data-aos="fade-right">
          <!-- <iframe :src="office.iframe" width="463" height="294" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->

          <GoogleMap :lng="office.lng" :lat="office.lat" :mklng="office.mklng" :mklat="office.mklat" />
        </div>
        <div class="contact-row-info" data-aos="fade-left">
          <h3 class="contact-row-info__title">
            {{ office.title }}
          </h3>
          <div class="contact-row-info__address">
            <div>{{ office.address1 }}</div>
            <div>{{ office.address2 }}</div>
          </div>
          <div class="contact-row-info__phone">
            <div>Telephone</div>
            <h4><a :href="`tel:${office.phone}`">{{ office.phone }}</a></h4>
          </div>
          <div class="contact-row-info__email">
            <div>Email</div>
            <h4><a :href="`mailto:${office.email}`">{{ office.email }}</a></h4>
          </div>
        </div>
      </div>

    </div>
  </b-container>
</div>
</template>

<script>
import ImgBanner from '@/components/ImgBanner'
import GoogleMap from '@/components/GoogleMap'

import axios from 'axios';

export default {
  data(){
    return {
      response: {}
    }
  },
  components: {
    ImgBanner,
    GoogleMap
  },
  methods: {
    fetchData(){
      axios.get('/contact').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.contact-wrapper {

}

.contact-row {
  height: calc(294em / 18);
  display: grid;
  grid-template-columns: calc(463em / 18) 1fr;
  grid-column-gap: calc(120em / 18);
  align-items: center;

  @media only screen and (max-width: 992px){
    grid-template-columns: 100%;
    height: auto;
  }

  &-map {
    width: calc(463em / 18);
    height: calc(294em / 18);

    @media only screen and (max-width: 992px){
      order: 2;
      max-width: 100%;
    }
  }

  &-info {

    @media only screen and (max-width: 992px){
      order: 1;
      margin-bottom: calc(10em / 14);
    }

    a {
      color: var(--black);
    }

    &__address {
      margin-bottom: calc(40em / 18);
    }

    &__phone {
      margin-bottom: calc(40em / 18);
    }

    &__email {
    }
  }


  & + & {
    margin-top: calc(95em / 18);

    @media only screen and (max-width: 992px){
      margin-top: calc(45em / 14);
    }
  }

  &:last-child {
    margin-bottom: calc(140em / 18);
  }
}
</style>
