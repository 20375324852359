<template>
<b-container>
  <h1>{{ h1 }}</h1>
</b-container>
</template>

<script>
export default {
  props: ['h1']
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: calc(60rem / 18);

  @media only screen and (max-width: 768px){
    margin-bottom: calc(15rem / 18);
  }
}
</style>
