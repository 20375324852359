<template>
<footer class="footer" :class="{'footer--sticky': sticky}">
  <div class="footer-main">
    <b-container>
      <div class="footer-main__tagline">
        Be part of our visualisation team
      </div>

      <div class="footer-main__nav">
        <div class="footer-nav">
          <div class="footer-nav__item" v-for="item in response.nav" :key="item.href">
            <a :href="item.href" class="footer-nav__link" target="_blank" v-if="isExternal(item.href)">{{ item.text }}</a>
            <router-link :to="item.href" class="footer-nav__link" v-else>{{ item.text }}</router-link>
          </div>
        </div>

        <a href="https://www.linkedin.com/company/laingorourke-service-limited/?viewAsMember=true" target="_blank" class="footer-main__nav-linkedin">
          <img src="@/assets/img/icons/linkedin.svg" alt="linkedIn">
        </a>
      </div>

      <div class="footer-main__actions">
        <router-link to="/jobs" class="btn btn--outline">Join Us</router-link>
      </div>

      <div class="footer-main__logo">
        <router-link to="/" class="footer-main__logo-link">
          <img src="@/assets/img/logo.svg" alt="Symmetry">
        </router-link>
        <div class="footer-main__logo-location">
          <span>LONDON</span>
          <span>HONG KONG</span>
          <span>SYDNEY</span>
        </div>
      </div>

      <div class="footer-main__external">
        <a href="https://www.laingorourke.com/" target="_blank">
          <img src="@/assets/img/laing.png" alt="Laing O'Rouke">
        </a>
      </div>

    </b-container>
  </div>
  <div class="footer-copyright">
    <b-container>
      <div class="footer-copyright__copyright">
        © Symmetry {{ year }}. All rights reserved. Disclaimer & Privacy Policy. Website by <a href="https://jump.com.hk/">Jump.</a>
      </div>
      <div class="footer-copyright__social">
        <a href="https://www.linkedin.com/company/laingorourke-service-limited/?viewAsMember=true" target="_blank" class="footer-copyright__linkedin">
          <img src="@/assets/img/icons/linkedin--white.svg" alt="linkedIn">
        </a>
      </div>
    </b-container>
  </div>
</footer>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      year: new Date().getFullYear(),
      sticky: false,
      response: {}
    }
  },
  methods: {
    fetchData(){
      axios.get('/footer').then(
        res=>{
          this.response = res.data;
        }
      )
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.footer {

  &-main {
    background-color: var(--grey);
    padding: calc(103em / 18) 0 calc(63em / 18);

    @media only screen and (max-width: 992px){
      padding: calc(27em / 14) 0;
    }

    > .container {
      display: grid;
      grid-template-columns: calc(675em / 18) 1fr;
      grid-template-rows: auto 1fr auto;
      grid-row-gap: calc(40em / 18);


      @media only screen and (max-width: 992px){
        grid-template-columns: calc(127em / 16) 1fr;
      }
    }

    &__tagline {
      font-size: calc(55em / 18);
      line-height: calc(72 / 55);

      @media only screen and (max-width: 992px){
        font-size: calc(30em / 14);
        grid-column: span 2;
        order: 1;
      }
    }

    &__nav {
      grid-row: span 2;
      text-align: right;
      margin-bottom: calc(30em / 18);

      &-linkedin {
        display: inline-block;
        margin-top: calc(30em / 18);

        @media only screen and (max-width: 992px){
          display: none;
        }
      }

      @media only screen and (max-width: 992px){
        order: 4;
        grid-row: span 1;
      }
    }

    &__actions {
      @media only screen and (max-width: 992px){
        order: 2;
        grid-column: span 2;

        .btn {
          width: calc(127rem / 18);
        }
      }
    }

    &__logo {

      &-link {
        width: 100%;

        &:hover, &:focus {
          text-decoration: none;
        }

        img {
          width: 100%;
          margin-bottom: calc(35em / 18);
        }
      }

      &-location {
        display: flex;
        justify-content: space-between;

        span {
          letter-spacing: 0.23em;
          color: var(--black);
          font-size: calc(15em / 18);
          font-family: 'Inter', sans-serif;

          @media only screen and (max-width: 992px){
            font-size: calc(8em / 18);
          }
        }
      }

      @media only screen and (max-width: 992px){
        order: 5;
        grid-column: span 2;
      }
    }

    &__external {
      text-align: right;

      @media only screen and (max-width: 992px){
        order: 3;
        width: calc(127rem / 18);

        img {
          width: 100%;
        }
      }
    }
  }

  &-copyright {
    background-color: #2D2D2D;
    color: var(--white);
    height: calc(36em / 18);

    @media only screen and (max-width: 992px){
      height: auto;
      padding: calc(15em / 14) 0;
    }

    > .container {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &__copyright {
      font-size: calc(12em / 18);
      font-family: 'inter', sans-serif;

      a {
        text-decoration: none;
        color: var(--white);
      }
    }

    &__social {
      display: none;

      @media only screen and (max-width: 992px){
        display: inline-block;
      }
    }
  }

  &--sticky {

  }
}

.footer-nav {


  &__item {


    & + & {
      margin-top: calc(15em / 18);
    }
  }

  &__link {
    color: var(--black);

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}
</style>
