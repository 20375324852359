<template>
<div>
  <VideoBanner v-bind="response.videoBanner" autoplay />
  <HOne :h1="response.h1"/>
  <TwoColText v-bind="response.twoColText" />
</div>
</template>

<script>
import axios from 'axios';
import VideoBanner from '@/components/VideoBanner'
import TwoColText from '@/components/TwoColText'
import HOne from '@/components/HOne'

export default {
  components: {
    VideoBanner,
    TwoColText,
    HOne
  },
  data(){
    return {
      response: {}
    }
  },
  methods: {
    fetchData(){
      axios.get('/studio').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: calc(46rem / 18);
}
</style>
