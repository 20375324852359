<template>
<div class="project-row">
  <b-container>
    <div class="project-row__label" @click="toggleOpen()">
      <div class="project-row__index">
        {{ leadingZero(index) }}.
      </div>
      <h2 class="project-row__name">
        {{ name }}
      </h2>
      <div class="project-row__arrow" :class="{'project-row__arrow--opened': isOpen}">
        <img src="@/assets/img/icons/arrow.svg" alt="toggle collapse">
      </div>
    </div>
  </b-container>
  <b-collapse class="project-row__collapse" v-model="isOpen">
    <div class="project-row__placeholder"></div>
    <GallerySlider :gallery="gallery" />
  </b-collapse>
</div>
</template>

<script>
import GallerySlider from '@/components/GallerySlider'

export default {
  props: ['name', 'gallery', 'index'],
  components: {
    GallerySlider
  },
  data(){
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen(){
      this.isOpen = !this.isOpen;
    }
  },
  mounted(){
    // this.toggleOpen();
  }
}
</script>

<style lang="scss" scoped>
.project-row {

  & + & {

    .project-row__label {
      border-top: 0;
    }
  }

  &__label {
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
    cursor: pointer;
    padding-top: calc(20em / 18);
    padding-bottom: calc(30em / 18);
    display: grid;
    grid-template-columns: calc(28em / 18) 1fr calc(40em / 18);
    grid-column-gap: calc(20em / 18);
  }

  &__index {
    font-weight: bold;
  }

  &__name {
    margin-bottom: 0;
  }

  &__arrow {
    transform: rotate(-45deg);
    transition: .3s;
    width: calc(45em / 18);
    height: calc(45em / 18);
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }

    &--opened {
      transform: rotate(0);
    }
  }

  &__collapse {
    position: relative;

    max-width: 1120px;
    margin: auto;
  }

  &__placeholder {
    padding-top: calc(100% / 16 * 9);

    @media only screen and (max-width: 768px){
      padding-top: calc(100% / 16 * 9 + 52em / 14); //ratio + control
    }
  }
}
</style>
