<template>
<div class="img-text" :class="{'img-text--img-left': imgLeft}">
  <div class="img-text-text" :data-aos="aosHelper(!imgLeft)">
    <div class="img-text-text__wrapper">
      <h2>{{ textH2 }}</h2>
      <div v-html="p"></div>
    </div>
  </div>
  <div class="img-text-img" :data-aos="aosHelper(imgLeft)">
    <div class="img-text-img__wrapper">
      <img :src="img" alt="imgH4" class="img-text-img__img">

      <div class="img-text-img__overlay">
        <h4>{{ imgH4 }}</h4>
        <h2>{{ imgH2 }}</h2>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['imgLeft', 'img', 'imgH4', 'imgH2', 'textH2', 'p'],
  methods: {
    aosHelper(boolean){ // boolean = is image on the left
      return boolean ? 'fade-right' : 'fade-left';
    }
  }
}
</script>

<style lang="scss" scoped>
.img-text {
  max-width: calc(1440em / 18);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media only screen and (max-width: 992px){
    grid-template-columns: 100%;
  }

  &-img {

    &__wrapper {
      padding-top: calc(100% * 743 / 708);

      @media only screen and (max-width: 992px){
        height: calc(520em / 14);
        padding: 0;
      }
    }

    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.2);
      color: var(--white);

      padding: calc(30em / 18);
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;

      h4 {
        width: calc(464rem / 18);
        margin: 0 auto;
        max-width: 100%;
        margin-bottom: calc(10rem / 18);
      }

      h2 {
        width: calc(464rem / 18);
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  &-text {
    padding-left: calc(30em / 18);
    padding-right: calc(30em / 18);
    display: flex;
    align-items: center;

    @media only screen and (max-width: 992px){
      padding: calc(40em / 14) calc(30em / 18);
    }

    &__wrapper {
      // overflow-y: auto;
      // max-height: calc(415em / 18);
      width: calc(420rem / 18);
      max-width: 100%;
      margin: auto;

      h2 {
        margin-bottom: calc(20rem / 18);
      }

      div {
      }
    }
  }

  &--img-left {
    .img-text {
      &-img {
        order: -1;

        @media only screen and (max-width: 992px){
          order: 0;
        }
      }
    }
  }
}
</style>
