<template>
<div class="gallery-slider">
  <!-- <Pano source="/360_cam03.jpg" /> -->
  <VueSlickCarousel v-bind="settings" ref="slider" @reInit="updateMeta" @afterChange="clearAnimationClass">
    <div class="gallery-slider__item" v-for="item in gallery" :key="item.type + item.src">
      <img :src="item.src">
    </div>
  </VueSlickCarousel>

  <div class="gallery-slider-control">
    <button class="btn btn--arrow-left-white" @click="prevSlide"></button>
    <div class="gallery-slider-control__pages">
      <span class="gallery-slider-control__current">
        {{ leadingZero(currentSlide) }}
      </span>
      <span class="gallery-slider-control__count">
        / {{ leadingZero(slideCount) }}
      </span>
    </div>
    <button class="btn btn--arrow-right-white" @click="nextSlide"></button>
  </div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  props: ['gallery'],
  components: {
    VueSlickCarousel,
  },
  data(){
    return {
      settings: {
        arrows: false,
        draggable: false,
        speed: 500,
        infinite: false,
        // touchMove: false,
        // swipe: false,
        // fade: true
      },
      currentSlide: 1,
      slideCount: 1
    }
  },
  methods: {
    nextSlide(){
      // this.clearAnimationClass();

      let comingSlide = this.currentSlide;

      if ( this.currentSlide < this.slideCount ){
        comingSlide++;
      }

      this.doAnimation(this.currentSlide, comingSlide);

      this.$refs.slider.next();
    },
    prevSlide(){
      // this.clearAnimationClass();

      let comingSlide = this.currentSlide;

      if ( this.currentSlide > 1 ){
        comingSlide--;
      }

      this.doAnimation(this.currentSlide, comingSlide);

      this.$refs.slider.prev();
    },
    updateMeta(){
      this.slideCount = this.$refs.slider.$refs.innerSlider.slideCount;
      this.currentSlide = this.$refs.slider.$refs.innerSlider.currentSlide + 1;
    },
    clearAnimationClass(){
      if ( this.$el.querySelector('.gallery-slider__item--forwards') ){
        this.$el.querySelector('.gallery-slider__item--forwards').classList.remove('gallery-slider__item--forwards')
      }

      if ( this.$el.querySelector('.gallery-slider__item--backwards') ){
        this.$el.querySelector('.gallery-slider__item--backwards').classList.remove('gallery-slider__item--backwards')
      }
    },
    doAnimation(oldIndex, newIndex){
      // this.clearAnimationClass();

      var current = this.$el.querySelector('.slick-current')

      console.log(current)


      // check direction
      if ( newIndex > oldIndex ){
        // go forwards
        console.log(current.nextSibling)
        console.log(current.nextSibling.classList)
        let item = current.nextSibling.querySelector('.gallery-slider__item')
        item.classList.add('gallery-slider__item--forwards')
        console.log(item.classList)

      } else if ( oldIndex > newIndex ) {
        // go backwards
        let item = current.previousSibling.querySelector('.gallery-slider__item')
        item.classList.add('gallery-slider__item--backwards')

      } else {
        // reach the edge
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &-control {
    position: absolute;
    right: calc(100em / 18);
    bottom: calc(23em / 18);
    display: flex;

    @media only screen and (max-width: 768px){
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(42em / 14);
      justify-content: center;
      align-items: center;
    }

    .btn {
      margin-right: calc(18rem / 18);
      order: -1;

      @media only screen and (max-width: 768px){
        order: initial;
        margin: 0;
      }
    }

    &__pages {
      color: var(--white);
      display: inline-block;

      @media only screen and (max-width: 768px){
        color: var(--text);
        margin: 0 calc(20em / 14);
      }
    }

    &__current {
      display: inline-block;
      font-size: calc(40em / 18);
    }

    &__count {
      display: inline-block;
      font-size: calc(20em / 18);
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: calc(100% / 1440 * 811);
    overflow: hidden;

    > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.gallery-slider__item {
  &--forwards {
    img {
      animation: for 0.5s;
    }
  }

  &--backwards {
    img {
      animation: back 0.5s;
    }
  }
}

@keyframes for {
  0% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(0,0);
  }
}

@keyframes back {
  0% {
    transform: translate(50%, 0);
  }

  100% {
    transform: translate(0,0);
  }
}
</style>
