<template>
<div class="service-wrapper">
  <div class="service" v-for="(service, index) in response.services" :key="service.img">

    <div class="service-info">

      <h2 class="service-info__title">
        {{ service.h2 }}
      </h2>

      <div class="service-info__desc" v-html="service.p"></div>

      <div class="service-info__control">
        <span class="service-info__page">{{ leadingZero(index + 1) }}</span>
        <span class="service-info__book"> / {{ leadingZero(response.services.length) }}</span>
      </div>
    </div>

    <div class="service-img">
      <img class="service-img__img" :src="service.img" :alt="service.h2">
    </div>

  </div>

  <div class="service-nav">
    <button class="service-nav__item" v-for="(service, i) in response.services" @click="scrollTo(i)" :class="{'service-nav__item--active': isActive(i)}" :key="service.img"></button>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import 'jquery-scrollify/jquery.scrollify.js'

export default {
  data(){
    return {
      response: {},
      currentIndex: 0
    }
  },
  methods: {
    fetchData(){
      axios.get('/service/getContent').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
    enableFixedHeader(){
      var header = document.querySelector('.header');
      header.classList.remove('header--sticky');
      header.classList.add('header--fixed');
    },
    disableFixedHeader(){
      var header = document.querySelector('.header');
      header.classList.remove('header--fixed');
      header.classList.add('header--sticky');
    },
    scrollTo(index){
      $.scrollify.move(index)
    },
    isActive(index){
      return this.currentIndex === index;
    }
  },
  created(){
    this.fetchData();
  },
  updated(){
    console.log('updated')
    var vm = this;
    if ($.scrollify.isDisabled()){
      $.scrollify.enable();
    } else if ( this.response ) {
      $.scrollify({
        section: ".service",
        updateHash: false,
        easing: "easeOutExpo",
        scrollSpeed: 1100,
        scrollbars: true,
        interstitialSection:"footer.footer",
        before(index){
          vm.currentIndex = index;
        }
      });
    }

  },
  mounted(){
    this.enableFixedHeader();
  },
  destroyed(){
    $.scrollify.disable()
    this.disableFixedHeader();
  }
}
</script>

<style lang="scss" scoped>
.service {
  max-width: calc(1440em / 18);
  margin: auto;
  // height: calc(100vh - 140em / 18); //client height - header
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr calc(100% * 808 / 1440);
  grid-column-gap: calc(100em / 18);

  @media only screen and (max-width: 992px){
    height: calc(100vh - 55em / 14); //client height - header
    grid-column-gap: calc(20em / 14);
  }

  @media only screen and (max-width: 768px){
    grid-template-columns: 100%;
    grid-template-rows: 1fr calc(220 / 520 * 100%);
  }

  &-info {
    grid-template-rows: 1fr auto 1fr;
    display: grid;
    grid-row-gap: calc(20em / 18);
    justify-content: flex-end;
    padding-left: 20px;

    @media only screen and (max-width: 768px){
      grid-template-rows: auto 1fr auto;
      padding-top: calc((60em + 55em) / 14);
      padding-bottom: calc(25em / 14);
      padding-left: calc(30em / 14);
      padding-right: calc(30em / 14);
    }

    &__title {
      max-width: calc(385rem / 18);
      align-self: flex-end;

      @media only screen and (max-width: 992px){
        max-width: 100%;
      }
    }

    &__desc {
      max-width: calc(385em / 18);

      @media only screen and (max-width: 992px){
        max-width: 100%;
      }
    }

    &__control {
      max-width: calc(385em / 18);
      align-self: center;

      @media only screen and (max-width: 992px){
        max-width: 100%;
      }
    }

    &__page {
      font-size: calc(63em / 18);

      @media only screen and (max-width: 768px){
        font-size: calc(40em / 14);
        font-weight: normal;
      }
    }

    &__book {
      font-size: calc(20em / 18);

      @media only screen and (max-width: 768px){
        font-size: calc(17em / 14);
        font-weight: bold;
      }
    }
  }

  &-img {
    position: relative;

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  &-nav {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: calc(40em / 18);
    display: grid;
    grid-row-gap: calc(14em / 18);

    @media only screen and (max-width: 768px){
      display: none;
    }

    &__item {
      width: calc(15em / 18);
      height: calc(15em / 18);
      border-radius: 50%;
      border: none;
      background-color: rgba(0,0,0,.05);
      transition: .3s;
      padding: 0;

      &--active {
        background-color: rgba(0,0,0,.2);
      }
    }

  }

}
</style>
