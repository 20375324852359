import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'


Vue.config.productionTip = false

Vue.mixin({
  methods: {
    isExternal(href){
      return /^http(s)?:\/\//.test(href);
    },
    leadingZero(number){
      var temp = '0' + number;

      return temp.slice(-2);
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import '@/scss/root.scss'
import '@/scss/typography.scss'
import '@/scss/grid.scss'
import '@/scss/button.scss'
