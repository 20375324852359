<template>
<div class="cv-form">
  <b-container>
    <div class="cv-form__text">
      <h1>
        I want to work with
      </h1>
      <img src="@/assets/img/logo.svg" alt="Symmetry">
      <p>If you can’t see the role for you but are interested in working with Symmetry. Send in your CV and we will be in touch.</p>
    </div>
    <div class="cv-form__form">
      <input type="text" v-model="model.name" placeholder="Your Name*" ref="name">
      <input type="text" v-model="model.email" placeholder="Email*" ref="email">

      <label ref="file">
        {{ fileName || 'Attach your CV*' }}
        <input type="file" hidden @change="fileChange($event)">
      </label>

      <button v-if="!isSubmitted" class="btn btn--fill" @click="submitButtonHandler()">SUBMIT</button>

      <div v-else>
        <h2>Thank you!</h2>
        <p>Your CV has been submitted. A member of our team will get back to you.</p>
      </div>

    </div>
  </b-container>
  <VueRecaptcha sitekey="6LfS-gckAAAAAHdk0up9ayQvGqeDnkGT2-O4dxqW" ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible" />
</div>
</template>

<script>
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    VueRecaptcha
  },
  data(){
    return {
      model: {
        name: '',
        email: '',
        file: ''
      },
      fileName: '',
      isSubmitted: false
    }
  },
  methods: {
    fileChange(e){
      // console.log(e)

      this.fileName = e.target.files[0].name;

      var formData = new FormData();
      formData.append('file', e.target.files[0])

      // console.log(formData);

      axios.post('/file-upload', formData).then(
        res=>{
          if ( res.data.status ){
            this.model.file = res.data.fileName;
          }
        }
      )
    },
    submitButtonHandler(){
      this.$refs.recaptcha.execute();
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified (recaptchaToken) {
      this.formSubmit(recaptchaToken);
    },
    formSubmit(token){
      this.resetError();

      let body = {}
      Object.assign(body, this.model)
      Object.assign(body, {token: token})

      axios.post('/form-submit', body).then(
        res=>{
          if ( res.data.status ){
            this.isSubmitted = true;
            this.formReset();
          } else {
            this.applyError(res.data.error)
          }
        }
      )
    },
    resetError(){
      let elArray = this.$el.querySelectorAll('.error');

      for ( let el of elArray){
        el.classList.remove('error');
      }
    },
    applyError(error){
      for ( let ref in error ){
        // console.log(ref);
        this.$refs[ref].classList.add('error')
      }
    },
    formReset(){
      this.model = {
        name: '',
        email: '',
        file: ''
      }
      this.fileName = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.cv-form {
  margin-bottom: calc(110em / 18);


  > .container {
    display: grid;
    grid-template-columns: calc(623em / 18) auto;
    grid-column-gap: calc(50em / 18);

    @media only screen and (max-width: 992px){
      grid-template-columns: auto calc(260em / 18)
    }

    @media only screen and (max-width: 768px){
      grid-template-columns: 100%;
    }
  }


  &__text {
    h1 {
      margin-bottom: calc(11rem / 18);
      color: var(--red);

      @media only screen and (max-width: 992px){
        font-size: calc(44em / 18);
      }
    }

    img {
      margin-bottom: calc(55em / 18);
      width: calc(560em / 18);

      @media only screen and (max-width: 992px){
        width: 100%;
      }
    }

    p {
      width: calc(364em / 18);

      @media only screen and (max-width: 768px){
        width: 100%;
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: calc(36em / 18);
    grid-auto-rows: calc(76em / 18);

    max-width: calc(365em / 18);

    @media only screen and (max-width: 768px){
      max-width: 100%;
    }

    input {
      font-size: calc(16em / 18);

      &[type="text"]{
        border: 1px solid var(--black);
        display: flex;
        align-items: center;
        padding: calc(10em / 16) calc(25em / 16);

        &::placeholder {
          color: var(--black);
          opacity: 1;
        }
      }

      &.error {
        border-color: var(--red);
      }
    }

    label {
      border: 1px solid var(--black);
      font-size: calc(16em / 18);
      display: flex;
      align-items: center;
      padding: calc(10em / 16) calc(25em / 16);
      cursor: pointer;

      &.error {
        border-color: var(--red);
      }
    }

    .btn {
      height: 100%;
      width: 100%;
    }

    div {
      h2 {
        color: var(--red);
      }
    }
  }
}
</style>
