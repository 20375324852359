<template>
<div class="img-text-action">
  <b-container>
    <div class="img-text-action__img">
      <img :src="img" :alt="h2">
    </div>
    <h2 class="img-text-action__h2">{{ h2 }}</h2>
    <div class="img-text-action__text" v-html="p"></div>
    <div class="img-text-action__actions">
      <a class="btn btn--outline" :href="btn.href" v-if="isExternal(btn.href)">{{ btn.text }}</a>
      <router-link class="btn btn--outline" :to="btn.href" v-if="!isExternal(btn.href)">{{ btn.text }}</router-link>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  props: ['img', 'h2', 'p', 'btn']
}
</script>

<style lang="scss" scoped>
.img-text-action {
  margin-bottom: calc(110em / 18);

  > .container {
    display: grid;
    grid-template-columns: calc(568em / 18) auto;
    grid-template-rows: 1fr auto 1fr;
    grid-column-gap: calc(57em / 18);

    @media only screen and (max-width: 992px){
      display: block;
    }
  }

  &__img {
    grid-row: span 3;

    @media only screen and (max-width: 992px){
      margin-bottom: calc(28em / 14);
    }

    img {
      width: 100%;
    }
  }

  &__h2 {
    align-self: flex-end;
  }

  &__text {
    margin-bottom: calc(30em / 18);
  }

  &__actions {
  }
}
</style>
