import { render, staticRenderFns } from "./StudioView.vue?vue&type=template&id=12336d05&scoped=true&"
import script from "./StudioView.vue?vue&type=script&lang=js&"
export * from "./StudioView.vue?vue&type=script&lang=js&"
import style0 from "./StudioView.vue?vue&type=style&index=0&id=12336d05&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12336d05",
  null
  
)

export default component.exports