import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StudioView from '../views/StudioView.vue'
import GalleryView from '../views/GalleryView.vue'
import ServicesView from '../views/ServicesView.vue'
import GalleryDetailView from '../views/GalleryDetailView.vue'
import JobsView from '../views/JobsView.vue'
import ContactView from '../views/ContactView.vue'
import ErrorView from '../views/ErrorView.vue'
import PageView from '../views/PageView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/studio',
    name: 'studio',
    component: StudioView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/gallery/:slug',
    name: 'gallery-detail',
    component: GalleryDetailView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: JobsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/:slug',
    name: 'page',
    component: PageView
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
