<template>
<div class="two-col-text">
  <b-container>
    <div class="two-col-text__left">
      <h2 v-if="h2">{{ h2 }}</h2>
    </div>

    <div class="two-col-text__right">
      <h3 v-if="h3">{{ h3 }}</h3>
      <div v-html="p"></div>
      <template v-if="gallery">
        <div class="two-col-text__gallery" v-for="img in gallery" :key="img.img">
          <img class="two-col-text__img" :src="img.img" alt="img.h2">
          <h2>{{ img.h2 }}</h2>
          <div v-html="img.p"></div>
        </div>
      </template>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  props: ['h2', 'h3', 'p', 'gallery']
}
</script>

<style lang="scss" scoped>
.two-col-text {
  margin-bottom: calc(55em / 18);

  > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(39em / 18);
    align-items: flex-start;

    @media only screen and (max-width: 768px){
      grid-template-columns: 100%;
    }
  }

  &__left {
    position: sticky;
    top: calc((140em + 40em) / 18);
    left: 0;

    @media only screen and (max-width: 992px){
      top: calc(55em / 14)
    }

    @media only screen and (max-width: 768px){
      position: static;
    }

    h2 {

    }

  }

  &__right {

    h3 {
      margin-bottom: calc(43rem / 18);

      @media only screen and (max-width: 768px){
        margin-bottom: calc(15rem / 14);
      }
    }

    > div {
      @media only screen and (max-width: 768px){
        margin-left: calc(80em / 14);
      }
    }
  }


  &__gallery {

  }

  &__img {
    max-width: 100%;
    margin-bottom: calc(20em / 18);

    @media only screen and (max-width: 768px){
      margin-left: calc(-80em / 14);
      max-width: calc(100% + 80em / 14);
    }
  }
}

</style>
