<template>
<div>
  <VideoBanner v-bind="response.videoBanner" />
  <TwoColText :h2="response.twoColText.h2" :h3="response.twoColText.h3" :p="response.twoColText.p"/>
  <GalleryListing />
</div>
</template>

<script>
import axios from 'axios';
import VideoBanner from '@/components/VideoBanner'
import TwoColText from '@/components/TwoColText'
import GalleryListing from '@/components/GalleryListing'

export default {
  components: {
    VideoBanner,
    TwoColText,
    GalleryListing
  },
  data(){
    return {
      response: {}
    }
  },
  methods: {
    fetchData(){
      axios.get('/gallery').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  },
  mounted(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: calc(46rem / 18);
}
</style>
