<template>
<article>
  <template v-for="(section, index) in response">
    <ImgBanner v-if="section.template === 'ImgBanner'" :text="section.text" :img="section.img" :key="section.template + index" />
    <HOne v-if="section.template === 'h1'" :h1="section.h1" :key="section.template + index" />
    <TwoColText v-if="section.template === 'TwoColText'" v-bind="section" :key="section.template + index" />
  </template>
</article>
</template>

<script>
import ImgBanner from '@/components/ImgBanner'
import TwoColText from '@/components/TwoColText'
import HOne from '@/components/HOne'

import axios from 'axios'

export default {
  components: {
    ImgBanner,
    TwoColText,
    HOne
  },
  data(){
    return ({
      response: []
    })
  },
  methods: {
    fetchData(){
      axios.get('/page?slug=' + this.$route.params.slug).then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
</style>
