<template>
<div class="grey-text">
  <b-container>
    <div class="grey-text__left">
      <h1>{{ h1 }}</h1>
      <img :src="img" :alt="h1">
    </div>
    <div class="grey-text__right">
      <h3>{{ h3 }}</h3>
      <div v-html="p"></div>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  props: ['h1', 'h3', 'p', 'img']
}
</script>

<style lang="scss" scoped>
.grey-text {
  background-color: var(--grey);
  padding: calc(100em / 18) 0 calc(80em / 18);
  margin-bottom: calc(80em / 18);

  @media only screen and (max-width: 768px){
    padding: calc(55em / 14) 0;
  }

  > .container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: calc(120 / 1180 * 100%);

    @media only screen and (max-width: 992px){
      grid-template-columns: 100%;
    }
  }

  &__left {

    @media only screen and (max-width: 992px){
      margin-bottom: calc(35em / 18);
    }
  }

  &__right {
    max-width: calc(350em / 18);

    @media only screen and (max-width: 992px){
      max-width: 100%;
    }
  }

  h1 {
    color: #4D4D4D;
    margin-bottom: calc(30rem / 18);
  }

  h3 {
    margin-bottom: calc(20rem / 18);
  }
}
</style>
