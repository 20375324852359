<template>
<div class="videobanner">
  <video :src="src" :poster="poster" :autoplay="autoplay" :controls="!autoplay" :playsinline="autoplay" :muted="autoplay" loop></video>
</div>
</template>

<script>
export default {
  props: {
    src: String,
    poster: String,
    autoplay: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.videobanner {
  padding-top: calc(100% / 21 * 9);
  position: relative;
  margin-bottom: calc(60em / 18);

  @media only screen and (max-width: 768px){
    margin-bottom: calc(25em / 14);
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
