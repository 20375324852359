<template>
  <div class="text-text-action">
    <b-container>
      <h2 class="text-text-action__h2">{{ h2 }}</h2>
      <div class="text-text-action__text" v-html="p1"></div>
      <div class="text-text-action__text" v-html="p2"></div>
      <div class="text-text-action__actions">
        <a class="btn btn--outline" :href="btn.href" v-if="isExternal(btn.href)">{{ btn.text }}</a>
        <router-link class="btn btn--outline" :to="btn.href" v-if="!isExternal(btn.href)">{{ btn.text }}</router-link>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ['h2', 'p1', 'p2', 'btn']
}
</script>

<style lang="scss" scoped>
.text-text-action {
  margin-bottom: calc(50em / 18);

  > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: calc(35em / 18);

    @media only screen and (max-width: 576px){
      display: block;
    }
  }

  &__h2 {
    grid-column: span 2;
    text-align: center;
    margin-bottom: calc(20rem / 18);

    @media only screen and (max-width: 576px){
      text-align: left;
    }
  }

  &__text {
    max-width: calc(387em / 18);
    justify-self: flex-end;
    text-align: right;
    padding-right: calc(20em / 18);

    & + & {
      border-left: 1px solid var(--red);
      justify-self: flex-start;
      text-align: left;
      padding-left: calc(20em / 18);
      padding-right: 0;
    }

    @media only screen and (max-width: 576px){
      text-align: left;
      padding: 0 !important;
      border: 0 !important;
      max-width: unset;
      margin-bottom: calc(30em / 18);
    }
  }

  &__actions {
    grid-column: span 2;
    text-align: center;

    @media only screen and (max-width: 576px){
      text-align: left;
    }
  }
}
</style>
