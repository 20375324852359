<template>
  <div class="home-video">
    <div class="home-video-video">
      <video class="home-video-video__video" src="https://player.vimeo.com/progressive_redirect/playback/752834800/rendition/1080p/file.mp4?loc=external&signature=aa3c71ea4ebd77be693587f19965c5a81c996b6fbd45af37fd46c62df4fed128" autoplay playsinline muted loop ref="video"></video>
    </div>
    <div class="home-video-overlay">
      <b-container>
        <h1 class="home-video-overlay__h1">
          {{ h1 }}
        </h1>
        <div class="home-video-overlay__p" v-html="p"></div>
        <div class="home-video-overlay__actions">
          <a class="btn btn--outline" :href="btn.href" v-if="isExternal(btn.href)">{{ btn.text }}</a>
          <router-link class="btn btn--outline" :to="btn.href" v-if="!isExternal(btn.href)">{{ btn.text }}</router-link>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeVideo',
  props: ['h1','p','btn'],
  watch: {
    $route(to){
      to.name === 'home' && this.$refs.video.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-video {
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  margin-bottom: calc(120em / 18);

  @media only screen and (max-width: 992px){
    padding-top: 0;
    margin-bottom: calc(33em / 14);
  }

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 992px){
      position: static;
      margin-bottom: calc(35em / 18);
    }

    &__video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 992px){
      position: relative;
      padding-bottom: calc(42em / 14);

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        width: calc(170em / 14);
        border-bottom: 5px solid var(--yellow);
      }
    }

    > .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__h1 {
      max-width: calc(646rem / 18);
      margin-bottom: calc(27rem / 18);
      color: var(--white);

      @media only screen and (max-width: 992px){
        color: var(--text);
        max-width: 100%;
      }
    }

    &__p {
      max-width: calc(494rem / 18);
      margin-bottom: calc(60rem / 18);
      color: var(--white);

      @media only screen and (max-width: 992px){
        color: var(--text);
        margin-bottom: calc(20em / 14);
        max-width: 100%;
      }
    }

    &__actions {
      max-width: calc(494rem / 18);

    }
  }
}
</style>
