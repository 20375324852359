import { render, staticRenderFns } from "./CompanySlider.vue?vue&type=template&id=89d6541a&scoped=true&"
import script from "./CompanySlider.vue?vue&type=script&lang=js&"
export * from "./CompanySlider.vue?vue&type=script&lang=js&"
import style0 from "./CompanySlider.vue?vue&type=style&index=0&id=89d6541a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d6541a",
  null
  
)

export default component.exports