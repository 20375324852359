<template>
  <span class="countup" v-b-visible.once="doCounting"></span>
</template>

<script>
import { CountUp } from 'countup.js';

export default {
  name: 'CountUp',
  props: ['prefix', 'number', 'suffix'],
  data(){
    return ({
      target: {},
    })
  },
  computed: {
    options(){
      return {
        prefix: this.prefix,
        suffix: this.suffix,
      }
    }
  },
  methods: {
    doCounting(status){
      if (status){
        this.target = new CountUp(this.$el, this.number, this.options);
        this.target.start();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
