<template>
<header class="header" :class="{'header--sticky': sticky}">
  <b-container class="container--header">
    <div class="header-logo">
      <router-link to="/" class="header-logo__link">
        <img src="@/assets/img/logo--white.svg" alt="" class="header-logo__img">
      </router-link>
      <div class="header-logo__location">
        <span>LONDON</span>
        <span>HONG KONG</span>
        <span>SYDNEY</span>
      </div>
    </div>

    <div class="header-nav">
      <div class="header-nav__item" :class="activeRouteHelper(item.href)" v-for="item in response.nav" :key="item.href">
        <a :href="item.href" class="header-nav__link" target="_blank" v-if="isExternal(item.href)">{{ item.text }}</a>
        <router-link :to="item.href" class="header-nav__link" v-else>{{ item.text }}</router-link>
      </div>
    </div>

    <div class="header-mobile">
      <button class="header-mobile__btn btn btn--clear" @click="isOpen = true">
        <img src="@/assets/img/icons/menu--white.svg" alt="mobile menu">
      </button>

      <div class="header-mobile__drawer">
        <b-sidebar v-model="isOpen" no-header no-footer width="100%" bg-variant="dark" right>
          <template #default="{ hide }">
            <div class="drawer">
              <div class="drawer-logo">
                <router-link to="/" class="header-logo__link" @click.native="hide">
                  <img src="@/assets/img/logo--white.svg" alt="" class="header-logo__img">
                </router-link>
                <div class="header-logo__location">
                  <span>LONDON</span>
                  <span>HONG KONG</span>
                  <span>SYDNEY</span>
                </div>
              </div>

              <button class="drawer-btn btn btn--clear" @click="isOpen = false">
                <img src="@/assets/img/icons/cross--white.svg" alt="close menu">
              </button>

              <div class="drawer-nav">
                <div class="drawer-nav__item" :class="activeRouteHelper(item.href)" v-for="item in response.nav" :key="item.href">
                  <a :href="item.href" class="drawer-nav__link" target="_blank" v-if="isExternal(item.href)" @click="hide">{{ item.text }}</a>
                  <router-link :to="item.href" class="drawer-nav__link" v-else @click.native="hide">{{ item.text }}</router-link>
                </div>
              </div>
            </div>
          </template>
        </b-sidebar>
      </div>
    </div>
  </b-container>
</header>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      sticky: true,
      isOpen: false,
      response: {}
    }
  },
  methods: {
    fetchHeader(){
      axios.get('/header').then(
        res=>{
          this.response = res.data;
        }
      )
    },
    activeRouteHelper(href){
      return this.$route.path.includes(href) ? 'active' : '';
    },
  },
  created(){
    this.fetchHeader();
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: calc(140em / 18);
  background-color: var(--black);
  color: var(--white);
  padding-top: calc(46em / 18);

  @media only screen and (max-width: 992px){
    height: calc(55em / 14);
    padding-top: calc(15em / 14);


  }

  > .container {
    display: grid;
    grid-template-columns: calc(426em / 18) 1fr;
    align-items: flex-start;

    @media only screen and (max-width: 992px){
      grid-template-columns: calc(216em / 14) 1fr;
    }
  }

  &-logo {

    @media only screen and (max-width: 992px){
      max-width: 100%;
    }

    &__link {
      text-decoration: none;

    }

    &__img {
      margin-bottom: calc(22em / 18);


      @media only screen and (max-width: 992px){
        max-width: 100%;
        margin-bottom: calc(6em / 14);
      }
    }

    &__location {
      display: flex;
      justify-content: space-between;

      span {
        color: #fff;
        font-size: calc(9em / 18);
        font-family: 'Inter', sans-serif;
        letter-spacing: 0.23em;

        @media only screen and (max-width: 992px){
          font-size: calc(5em / 14);
        }
      }
    }
  }

  &-nav {
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 992px){
      display: none;
    }

    &__item {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -5px;
        left: 0;
        border-bottom: 3px solid var(--red);
        opacity: 0;
        transition: .5s;
      }

      &.active {

        &:after {
          opacity: 1;
        }
      }

      & + & {
        margin-left: calc(27em / 18);
      }
    }

    &__link {
      color: var(--white);
      font-size: calc(16em / 18);
      text-decoration: none;
    }
  }

  &-mobile {
    justify-self: flex-end;
    display: none;

    &__btn {

    }

    &__drawer {

      .b-sidebar {
        background-color: var(--black) !important;
      }
    }



    @media only screen and (max-width: 992px){
      display: block;
    }
  }

  &--sticky {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1024;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1024;
  }
}

.drawer {
  padding: 0 30px;
  display: grid;
  grid-template-columns: calc(216em / 14) 1fr;
  padding-top: calc(15em / 14);
  align-items: flex-start;

  &-logo {
    margin-bottom: calc(70em / 18);
  }

  &-btn {
    justify-self: flex-end;
  }

  &-nav {

    &__item {
      margin-bottom: calc(25em / 18);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__link {
      color: var(--white);
      font-size: calc(24em / 14);

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}
</style>

<style>
.header-mobile__drawer .b-sidebar {
  background-color: var(--black) !important;
}
</style>
