<template>
<div class="office-map">
  <b-container>
    <h2 class="office-map-title">
      Office Locations
    </h2>
    <div class="office-map-map" ref="pad">
      <div class="office-map-map__map">
        <img src="@/assets/img/world-map.png" alt="world map">
        <div class="office-map-map__marker office-map-map__marker--left office-map-map__marker--london" data-aos="fade-right">
          LONDON
        </div>
        <div class="office-map-map__marker office-map-map__marker--left office-map-map__marker--hongkong" data-aos="fade-left">
          HONG KONG
        </div>
        <div class="office-map-map__marker office-map-map__marker--left office-map-map__marker--sydney" data-aos="fade-left">
          SYDNEY
        </div>
      </div>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.office-map {
  margin-bottom: calc(90em / 18);

  &-title {
    padding-bottom: calc(30rem / 18);
    border-bottom: 1px solid var(--black);
    margin-bottom: 0;
  }

  &-map {
    cursor: none;

    &__map {
      position: relative;

      img {
        width: 100%;
      }
    }

    &__marker {
      display: flex;
      position: absolute;
      align-items: center;
      font-size: calc(28em / 18);

      @media only screen and (max-width: 768px){
        font-size: calc(17em / 14);
        font-weight: bold;
      }

      &--left {
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: calc(10em / 18);
          background-color: var(--black);
        }
      }

      &--right {
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: calc(10em / 18);
          background-color: var(--black);
        }
      }

      &--london {
        top: calc(155 / 708 * 100%);
        left: calc(105 / 1120 * 100%);

        @media only screen and (max-width: 768px){
          top: calc(150 / 708 * 100%);
          left: calc(100 / 1120 * 100%);
        }
      }

      &--hongkong {
        top: calc(320 / 708 * 100%);
        left: calc(710 / 1120 * 100%);

        @media only screen and (max-width: 768px){
          top: calc(320 / 708 * 100%);
          right: calc(395 / 1120 * 100%);
          left: unset;
        }
      }

      &--sydney {
        top: calc(575 / 708 * 100%);
        left: calc(905 / 1120 * 100%);

        @media only screen and (max-width: 768px){
          top: calc(565 / 708 * 100%);
          right: calc(190 / 1120 * 100%);
          left: unset;
        }
      }

      &--hongkong, &--sydney {
        @media only screen and (max-width: 768px){
          flex-direction: row-reverse;


          &:before {
            margin-right: 0;
            margin-left: calc(10em / 18);
          }
        }
      }
    }
  }
}
</style>
