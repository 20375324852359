<template>
  <div id="app">
    <HeaderView />
    <main>
      <keep-alive include="HomeView">
        <router-view :key="$route.path" />
      </keep-alive>
    </main>
    <FooterView />
    <div class="rocket" ref="rocket">
      <img src="@/assets/img/icons/rocket.svg" alt="marker">
    </div>
  </div>
</template>

<script>
import HeaderView from '@/views/HeaderView'
import FooterView from '@/views/FooterView'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  components: {
    HeaderView,
    FooterView
  },
  methods: {
    launchRocket(){
      var rocket = this.$refs.rocket;

      var pad = this.$el;

      pad.onmouseenter = (event) => {
        rocket.style.opacity = "1";

        var x = event.clientX + "px";
        var y = event.clientY + "px";


        // rocket.style.transition = "0.3s ease-out";
        rocket.style.position = "fixed";
        rocket.style.left = x;
        rocket.style.top = y;
      }


      pad.onmousemove = (event) => {
        var x = event.clientX + "px";
        var y = event.clientY + "px";


        // rocket.style.transition = "0.3s ease-out";
        rocket.style.position = "fixed";
        rocket.style.left = x;
        rocket.style.top = y;
      }

      pad.onmouseleave = (event) => {
        console.log(event)

        // rocket.style.position = "absolute";
        rocket.style.opacity = "0";
        // rocket.style.left = '0';
        // rocket.style.top = '50%';
      }

    }
  },
  mounted(){
    this.launchRocket();
    AOS.init({
      once: true
    });
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  cursor: none;

  @media only screen and (max-width: 1366px){
    cursor: auto;
  }
}
</style>

<style scoped lang="scss">
.rocket {
  position: fixed;
  display: inline-block;
  transform: translate(-50%, -50%);
  width: calc(70em / 18);
  height: calc(70em / 18);
  z-index: 10240;
  pointer-events: none;
  background-color: rgba(255,255,255,0.2);
  border-radius: 50%;

  @media only screen and (max-width: 1366px){
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    filter: brightness(0);
  }
}
</style>
