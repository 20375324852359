<template>
  <div class="grid-numbers" :class="numberOfGrids % 2 ? 'grid-numbers--odd' : 'grid-numbers--even'">
    <div class="grid-numbers__container">
      <div class="grid-numbers__grid" v-for="grid in grids" :key="grid.number + grid.caption">
        <div class="grid-numbers__number">
          <CountUp :prefix="grid.prefix" :number="grid.number" :suffix="grid.suffix" />
        </div>
        <h3 class="grid-numbers__caption">
          {{grid.caption}}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import CountUp from '@/components/CountUp'

export default {
  name: 'GridNumbers',
  props: ['grids'],
  components: {
    CountUp
  },
  computed: {
    numberOfGrids(){
      return this.grids.length;
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-numbers {
  margin-bottom: calc(150em / 18);

  @media only screen and (max-width: 768px){
    margin-bottom: calc(50em / 14);
  }

  &__container {
    max-width: 1440px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(v-bind(numberOfGrids), minmax(0, 1fr));

    @media only screen and (max-width: 1200px){
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 576px){
      grid-template-columns: 100%;
    }
  }

  &__grid {
    text-align: center;
    padding-top: calc(60em / 18);
    position: relative;
    height: calc(400em / 18);

    &:nth-child(odd) {
      background-color: var(--grey);
      color: var(--black);

      &:after {
        color: var(--red);
      }
    }

    &:nth-child(even) {
      background-color: var(--black);
      color: var(--white);

      &:after {
        color: var(--yellow);
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: calc(62em / 18);
      border-bottom: 5px solid currentColor;
      left: 50%;
      bottom: calc(74em / 18);
      transform: translateX(-50%);
    }
  }

  &__number {
    font-size: calc(108em / 18);
    // margin-bottom: calc(15rem / 18);
  }

  &__caption {
    max-width: calc(230rem / 18);
    margin: auto;
  }

  &--odd {
    background-color: var(--grey);
  }

  &--even {
    background-image: linear-gradient(to right, var(--grey) 50%, var(--black) 50%);
  }
}
</style>
