<template>
<div>
  <ImgBanner :text="response.imgBanner.text" :img="response.imgBanner.img" />

  <ImgText v-for="row in response.imgText" :img="row.img" :imgH4="row.imgH4" :imgH2="row.imgH2" :textH2="row.textH2" :p="row.p" :imgLeft="row.imgLeft" :key="row.img" />

  <CompanySlider :companies="response.companySlider.companies" />

  <GreyText :h1="response.greyText.h1" :h3="response.greyText.h3" :p="response.greyText.p" :img="response.greyText.img" />

  <OfficeMap />

  <CVForm />
</div>
</template>

<script>
import ImgBanner from '@/components/ImgBanner'
import ImgText from '@/components/ImgText'
import CompanySlider from '@/components/CompanySlider'
import GreyText from '@/components/GreyText'
import OfficeMap from '@/components/OfficeMap'
import CVForm from '@/components/CVForm'

import axios from 'axios'

export default {
  name: 'JobsView',
  components: {
    ImgBanner,
    ImgText,
    CompanySlider,
    GreyText,
    OfficeMap,
    CVForm
  },
  data(){
    return {
      response: {}
    }
  },
  methods: {
    fetchData(){
      axios.get('/jobs').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
</style>
