<template>
<div class="gallery-listing">
  <router-link :to="cate.href" class="gallery-card" v-for="cate in response" :key="cate.name + cate.img" data-aos="fade-up" data-aos-offset="0">
    <div class="gallery-card__img">
      <img :src="cate.img" :alt="cate.name">
    </div>
    <div class="gallery-card__name">{{ cate.name }}</div>
  </router-link>
</div>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      response: []
    }
  },
  methods: {
    fetchData(){
      axios.get('/project/category').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.gallery-listing {
  max-width: calc(1440em / 18);
  margin: 0 auto calc(80em / 18);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: calc(10em / 18);
  grid-row-gap: calc(45em / 18);

  @media only screen and (max-width: 576px){
    grid-template-columns: 100%;
  }
}

.gallery-card {

  &:hover, &:focus {
    text-decoration: none;

    img {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }

  &__img {
    width: 100%;
    padding-top: calc(100% / 715 * 402);
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: .5s;
    }
  }

  &__name {
    font-size: calc(28em / 18);
    text-align: center;
    text-decoration: none;
    color: var(--black);

    @media only screen and (max-width: 768px){
      font-weight: bold;
      font-size: calc(17em / 14);
    }
  }
}
</style>
