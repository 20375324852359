<template>
  <div ref="gmap" class="google-map"></div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    lat: Number,
    lng: Number,
    mklat: Number,
    mklng: Number,
    title: String,
    loaded: Boolean
  },
  data(){
    return ({
      icon: '',
      style: []
    })
  },
  computed: {
    marker(){
      return { lat: this.mklat, lng: this.mklng };
    },
    location(){
      return { lat: this.lat, lng: this.lng };
    },
  },
  watch: {
    loaded(boolean){
      if (boolean) {
        this.initGmap();
      }
    }
  },
  methods: {
    initGmap(){
      // eslint-disable-next-line
      const map = new google.maps.Map(this.$refs.gmap, {
        zoom: 17,
        center: this.location,
        styles: this.style,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });

      // eslint-disable-next-line
      new google.maps.Marker({
        position: this.marker,
        map,
        title: this.title || '',
        icon: this.icon
      });
    }
  },
  mounted(){
    this.initGmap();
  }
}
</script>

<style lang="scss" scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>
