<template>
<div class="gallery-detail">
  <b-container>
    <h1>
      {{ response.h1 }}
    </h1>
  </b-container>

  <ProjectRow v-for="(project, index) in response.projects" v-bind="project" :index="index + 1" :key="project.name + (index + 1)" />

  <b-container>
    <div class="gallery-detail-control">
      <div class="gallery-detail-control__prev">
        <router-link :to="response.prev.href" class="btn btn--arrow-left">
          {{ response.prev.text }}
        </router-link>
      </div>
      <div class="gallery-detail-control__loadmore">
        <button class="btn btn--outline" @click="loadProjects()">
          LOAD MORE
        </button>
      </div>
      <div class="gallery-detail-control__next">
        <router-link :to="response.next.href" class="btn btn--arrow-right">
          {{ response.next.text }}
        </router-link>
      </div>
      <div class="gallery-detail-control__nomore" v-if="noMore">
        <h3>No more to load</h3>
      </div>
    </div>
  </b-container>
     <!-- {{ $route.params.slug }} -->
</div>
</template>

<script>
import axios from 'axios'
import ProjectRow from '@/components/ProjectRow'

export default {
  components: {
    ProjectRow
  },
  data(){
    return {
      currentPage: 0,
      response: {},
      noMore: false
    }
  },
  watch: {
    noMore(boolean){
      if ( boolean ){
        setTimeout(()=>{
          this.noMore = false;
        },
        3000)
      }
    }
  },
  methods: {
    fetchData(){
      return axios.get('/project/detail?slug=' + this.$route.params.slug).then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
    loadProjects(){
      this.currentPage++;

      var endpoint = '/projects?';

      var param = {
        slug: this.$route.params.slug,
        page: this.currentPage
      }

      endpoint += new URLSearchParams(param).toString()

      // console.log(endpoint)

      axios.get(endpoint).then(
        res => {

          if ( res.data.projects.length ){
            // console.log(res.data.projects)
            this.response.projects = [...this.response.projects, ...res.data.projects]
            // console.log(this.response.projects)
            this.currentPage = res.data.page
          } else {
            this.noMore = true;
          }
        }
      )
    }
  },
  created(){
    this.fetchData().then(()=>this.loadProjects())
  }
}
</script>

<style lang="scss" scoped>
.gallery-detail {
  margin-top: calc(68em / 18);
  margin-bottom: calc(90em / 18);

  h1 {
    margin-bottom: calc(56rem / 18);
  }

  &-control {
    margin-top: calc(65em / 18);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: calc(20em / 18);

    @media only screen and (max-width: 768px){
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__prev {
      @media only screen and (max-width: 768px){
        .btn {

          &:before {
            font-size: calc(16rem / 18);
          }
        }
      }
    }

    &__loadmore {
      text-align: center;

      @media only screen and (max-width: 768px){
        order: -1;
        grid-column: span 2;
        align-self: center;
        margin-bottom: calc(30em / 18);
      }
    }

    &__next {
      justify-self: right;

      @media only screen and (max-width: 768px){
        .btn {

          &:after {
            font-size: calc(16rem / 18);
          }
        }
      }
    }

    &__nomore {
      grid-column: span 3;
      color: var(--red);
      text-align: center;
      margin-top: calc(30em / 18);

      @media only screen and (max-width: 768px){
        grid-column: span 2;
      }
    }
  }
}
</style>
