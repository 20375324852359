<template>
<div>
  <keep-alive include="LandingVideo">
    <LandingVideo v-if="(!isMobile && !developMode)" />
  </keep-alive>
  <HomeVideo v-bind="response.homeVideo" />
  <TextTextAction v-bind="response.textTextAction"/>
  <GridNumbers v-if="response.gridNumbers" v-bind="response.gridNumbers" />
  <ImgTextAction v-bind="response.imgTextAction" />
  <RollingH1 v-bind="response.rollingH1" />
  <GalleryListing />
</div>
</template>

<script>
import LandingVideo from '@/components/LandingVideo'
import HomeVideo from '@/components/HomeVideo'
import TextTextAction from '@/components/TextTextAction'
import GridNumbers from '@/components/GridNumbers'
import ImgTextAction from '@/components/ImgTextAction'
import RollingH1 from '@/components/RollingH1'
import GalleryListing from '@/components/GalleryListing'

import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    LandingVideo,
    HomeVideo,
    TextTextAction,
    GridNumbers,
    ImgTextAction,
    RollingH1,
    GalleryListing
  },
  data(){
    return {
      developMode: false,
      response: {}
    }
  },
  methods: {
    fetchData(){
      axios.get('/home').then(
        res=>{
          this.response = res.data;
        }
      ).catch(()=>{
        this.$router.push('/error')
      })
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768
    }
  },
  created(){
    this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
</style>
