<template>
  <div class="error-view">
    <h2>Somthing went Wrong</h2>
    <router-link class="btn btn--outline" to="/">Back to Home Page</router-link>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.error-view {
  max-width: calc(1440em / 18);
  margin: auto;
  padding: calc(60em / 18) 20px;
  min-height: calc(250em / 18);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  h2 {
    flex-basis: 100%;
    text-align: center;
  }

  .btn {
    align-self: flex-start;
  }
}
</style>
