<template>
<div class="img-banner" v-if="img">
  <div class="img-banner-img">
    <img :src="img" :alt="text ||''" class="img-banner-img__img">
  </div>
  <div class="img-banner-overlay" v-if="text">
    <h1 class="img-banner-overlay__text">
      {{ text }}
    </h1>
  </div>
</div>
</template>

<script>
export default {
  props: ['text', 'img']
}
</script>

<style lang="scss" scoped>
.img-banner {
  width: 100%;
  position: relative;
  margin-bottom: calc(120em / 18);

  @media only screen and (max-width: 768px){
    margin-bottom: calc(25em / 14);
  }

  &-img {
    width: 100%;
    padding-top: calc(100% * 352 / 1440);
    position: relative;

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: var(--white);
      text-shadow: 0 0 6px #333;
    }
  }
}
</style>
